.container {
    flex: 1;
    display: flex;
}

.wrapper {
    padding: 14px 36px;
    flex: 1;
    display: flex;
}

@media (max-width: 1024px) {

    .wrapper {
        padding: 14px 24px;
    }

    .container {
        margin-left: 0px;
        padding-bottom: 58px;
    }

}