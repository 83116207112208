.container {
    flex: 1;
    padding-bottom: 60px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.dashboards {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.dashboardLinha {
    display: flex;
    gap: 24px;
}

.prefiltros {
    flex: 1;
    display: flex;
    align-items: center;
}

.prefiltro {
    font-size: 12px;
    font-weight: 500;
    color: var(--text);
    padding: 10px 16px;
    cursor: pointer;
    border: 1px solid var(--border);
    font-weight: 700;
    background-color: var(--background);
}

.prefiltro:first-child {
    border-radius: 8px 0 0 8px;
    border-right: 0;
}

.prefiltro:last-child {
    border-radius: 0 8px 8px 0;
    border-left: 0;
}

@media (max-width: 1366px) {}