.container {
    border-top: 1px solid var(--border);
    width: 520px;
}

.acoes {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding: 24px 24px 0px 24px;
    border-top: 1px solid var(--border);
}

.campos {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}