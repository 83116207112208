.guia {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    gap: 8px;
    width: 600px;
}

.imageContainer {
    width: 100%;
    height: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--border);
}

.imagem {
    width: 100%;
    height: 100%;
    object-fit: contain;
    color: var(--primary);
}

.titulo {
    padding-top: 32px;
    font-size: 18px;
    font-weight: 600;
    color: var(--text);
    text-align: center;
}

.subtitulo {
    color: var(--textSecondary);
    text-align: center;
}

.progresso {
    padding-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.ativo {
    background-color: var(--primary);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    cursor: pointer;
}

.inativo {
    background-color: var(--border);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    cursor: pointer;
}

.botoes {
    padding-top: 32px;
    width: 100%;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
}