.titulo {
    flex: 1;
    font-size: 16px;
    font-weight: 700;
    color: var(--text);
    padding-bottom: 8px;
}

.graficosWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.graficoCategorias {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 36px;
}

.semTransacoes {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--textoSecondary);
}