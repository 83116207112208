body,
html,
textarea,
#root {
  height: 100%;
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: var(--text);
  cursor: default;
  background-color: var(--background);
}

div,
input,
textarea {
  box-sizing: border-box;
}

input,
textarea {
  font-family: "Mulish", sans-serif !important;
  background-color: var(--background) !important;
}

input::placeholder {
  color: var(--placeholder);
}

input:disabled {
  pointer-events: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit !important;
  text-decoration: none;
}

.material-icons,
.material-icons-outlined,
.material-icons-round,
.material-symbols-rounded,
.material-symbols-outlined {
  font-size: 18px !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--background) !important;
  margin: 8px 0;
}

::-webkit-scrollbar-thumb {
  background: var(--border) !important;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--border) !important;
}

button {
  cursor: pointer;
  border: none;
  padding: 8px 16px;
  color: var(--text);
  border-radius: 4px;
}

button:disabled {
  cursor: not-allowed;
  background-color: var(--light-gray) !important;
  border: 1px solid var(--border) !important;
}

:root[data-theme="light"] {
  --text: #414651;
  --textSecondary: #535862;
  --background: #FDFDFD;
  --primary: #1570EF;
  --secondary: #1570EF10;
  --accent: #7e90be;
  --light-gray: #FFFFFF;
  --thumb: #a8a8a8;
  --border: #E9EAEB;
  --white: #FFFFFF;
  --placeholder: #535862;
  --warning: #DC6803;
  --error: #D92D20;
  --success: #079455;
  --success-light: #DCFAE6;
  --yellow: #F6A600;
  --info: #1570ef;
  --medium-gray: #F5F5F5;
  --opacity: rgb(3, 3, 3, .5);
  --shadow: rgba(0, 0, 0, 0.1);

  --positive: var(--primary);
  --negative: #F04438;
}

:root[data-theme="dark"] {
  --text: #f0f0f0;
  --textSecondary: #A4A7AE;
  --background: #13111c;
  --primary: #1570EF;
  --secondary: #1570EF10;
  --accent: #415381;
  --light-gray: #181622;
  --thumb: #3d3d3d;
  --border: #22262F;
  --white: #FFFFFF;
  --placeholder: #A4A7AE;
  --warning: #DC6803;
  --error: #D92D20;
  --success: #079455;
  --yellow: #F6A600;
  --success-light: #DCFAE6;
  --info: #1570ef;
  --medium-gray: #1A1A1A;
  --opacity: rgb(3, 3, 3, .5);
  --shadow: rgba(255, 255, 255, 0.1);

  --positive: var(--primary);
  --negative: #F04438;

}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.spin {
  animation: spin 1s linear infinite;
}

iframe {
  border: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px var(--background) inset !important;
  -webkit-text-fill-color: var(--text) !important;
  color: var(--text) !important;
  transition: background-color 5000s ease-in-out 0s;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}