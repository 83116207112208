.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--opacity);
    z-index: 9998;
    overflow: none;
}

.highlight {
    right: 0;
    position: absolute;
    border-radius: 8px;
    background-color: transparent;
    z-index: 9999;
    pointer-events: all;
    cursor: pointer;
    /* Permite clique */
}

.message {
    position: absolute;
    background-color: var(--background);
    color: var(--text);
    padding: 16px;
    border-radius: 4px;
    margin-top: 5px;
    pointer-events: auto;
    z-index: 10000;
    right: 0;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-direction: column;
    font-size: 14px;
    box-shadow: 0px 0px 3px var(--shadow);
}

.next {
    margin-top: 4px;
    padding: 0px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
}

.botao {
    border: 1px solid var(--border);
    color: var(--text);
    padding: 4px 8px;
    border-radius: 4px;
}