.container {
    padding: 8px 0px 0px 0px;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.containerTitulo {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
}

.containerChildren {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.titulo {
    font-size: 18px;
    font-weight: 600;
    color: var(--text);
}

.subtitulo {
    font-size: 14px;
    font-weight: 400;
    color: var(--textSecondary);
}