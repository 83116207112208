.container {
    border-top: 1px solid var(--border);
}

.acoes {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding: 24px 24px 0px 24px;
    border-top: 1px solid var(--border);
}

.campos {
    flex: 1;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-width: 320px;
}

.paineis {
    padding: 12px;
    display: flex;
}

.alvos {
    flex: 1;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
}

.alvosTitulo {
    font-size: 14px;
    font-weight: 700;
    color: var(--text);
    ;
    margin-bottom: 8px;
}

.alvosOpcoes {
    width: 100%;
    gap: 16px;
    display: flex;
    align-items: center;
}

.switch {
    min-height: 64px;
}