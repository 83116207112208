.container {
    background-color: var(--light-gray);
    border-radius: 12px;
    border: 1px solid var(--border);
}

.wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
}