.container {
    display: flex;
    flex-direction: column;
}

.wrapper {
    flex: 1;
}

.cabecalho {
    background-color: var(--background);
    padding: 48px 20vw 12px 20vw;
    display: flex;
    flex-direction: column;
    gap: 18px;
    background-color: var(--light-gray);
}

.corpo {
    padding: 16px 20vw;
    background-color: var(--light-gray);
}

.titulo {
    font-size: 24px;
    color: var(--texto);
    font-weight: 700;
}

.conteudo {
    padding: 24px 18px 36px 18px;
}

.conteudoTitulo {
    color: var(--texto);
    font-size: 24px;
    padding: 0px 0px 0px 0px;
}

.conteudoTopico {
    color: var(--textoSecondary);
    font-size: 16px;
    font-weight: 700;
    padding: 24px 0px 12px 0px;
}

.conteudoTexto {
    font-size: 16px;
    color: var(--texto);
}



@media (max-width: 768px) {}