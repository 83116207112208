.container {
    background-color: var(--background);
    color: var(--text);
    border-radius: 16px;
    cursor: default;
}

.wrapper {
    display: flex;
    border-radius: 16px;
    flex-grow: 1;
    align-items: stretch;
}

.sidebar {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 160px;
    font-size: 12px;
    font-weight: 600;
    padding: 16px;
}

.option {
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 4px;
}

.option:hover {
    background: var(--light-gray);
}


.navegacao {
    user-select: none;
    padding: 8px;
    font-size: 18px !important;
    color: var(--text);
    cursor: pointer;
    background-color: var(--light-gray);
    border-radius: 16px;
}

.calendarContainer {
    border-left: 1px solid var(--border);
    flex: 1;
    display: flex;
}

.month {
    font-size: 14px;
    font-weight: 600;
}

.calendar {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    text-align: center;
    justify-content: flex-start;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
}

.weekday {
    font-size: 12px;
    font-weight: 600;
}

.weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 4px;
}

.days {
    max-height: 300px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 4px;
}

.day {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    cursor: pointer;
    border-radius: 50%;
    user-select: none;
    font-size: 12px;
    font-weight: 600;
}

.day:hover {
    background: var(--light-gray);
    color: var(--primary);
}

.selected {
    border: 1px solid var(--secondary);
    background: var(--secondary);
    color: var(--primary);
}

.start {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}

.end {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}

.footer {
    border-top: 1px solid var(--border);
    display: flex;
    justify-content: flex-end;
    padding: 24px 24px 0 24px;
    gap: 8px;
}

.empty {
    width: 32px;
    height: 32px;
}

.separador {
    height: 1px;
    background: var(--border);
    margin: 4px 0 4px 0;
}