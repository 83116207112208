.container {
    cursor: pointer;
    position: relative;
}

.options {
    position: absolute;
    background: var(--background);
    z-index: 1;
    border: 1px solid var(--border);
    border-radius: 8px;
    margin-top: 4px;
    max-height: 280px;
    overflow-y: auto;
    min-width: 240px;
    padding: 8px 0;
}

.option {
    min-height: 34px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--text);
    font-size: 14px;
    padding: 8px 12px;
}

.option:hover {
    background: var(--light-gray);
}

.icone {
    color: var(--placeholder);
}

.labelContainer {
    display: flex;
    gap: 4px;
}

.label {
    font-size: 14px;
    font-weight: 500;
    color: var(--text);
    ;
}

.wrapperValores {
    display: flex;
    align-items: center;
    border: 1px solid var(--border);
    gap: 8px;
    padding: 9px 12px;
    border-radius: 8px;
    background-color: var(--background);
}

.input {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: var(--text);
    outline: none;
    cursor: pointer;
    font-weight: 400;
}

.obrigatorio {
    font-size: 12px;
    color: #FF4D4F;
}

.valor {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Mostra "..." quando o valor ultrapassa o limite */
    max-width: 100%;
}