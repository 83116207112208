.container {
    cursor: pointer;
}

.checkbox {
    width: 16px;
    height: 16px;
    border: 1px solid var(--border);
    border-radius: 4px;
    background-color: var(--background);
    outline: none;
    transition: background-color 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checked {
    background-color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
}

.enabled {
    background-color: var(--background);
    border: 1px solid var(--border);
}

.icone {
    font-size: 12px !important;
    color: var(--white);
    transition: color 0.2s;
}