.container {
    position: relative;
    border: 1px solid var(--border);
    border-radius: 12px;
    padding: 18px 24px;
    background-color: var(--light-gray);
}

.wrapper {
    height: 100%;
}

.corpo {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
}

.rodape {
    display: flex;
    justify-content: flex-end;
}

.botao {
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    color: var(--primary);
}

.bloqueioContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}

.bloqueioWrapper {
    height: 100%;
    width: 100%;
    border-radius: 12px;
    flex: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    backdrop-filter: blur(8px);
}

.bloqueioIcone {
    font-size: 24px !important;
    color: var(--warning);
}

.bloqueioTexto {
    font-size: 14px !important;
    font-weight: 600;
    color: var(--text);
    text-align: center;
}