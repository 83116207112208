.container {
    flex: 1;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.fileInput {
    display: none;
}

.resultList {
    margin-top: 20px;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: 1px solid var(--border);
    border-radius: 8px;
    padding: 24px;
    cursor: pointer;
    z-index: 1;
}

.inputWrapper:hover,
.inputWrapper:focus,
.inputWrapper:focus-within,
.inputWrapper:active {
    border-color: var(--primary);
    background-color: rgba(0, 102, 255, 0.05);
}

.dragOver {
    border-color: var(--primary);
    background-color: rgba(0, 102, 255, 0.05);
}

.label {
    font-size: 14px;
    color: var(--text);
}

.labelSecundaria {
    font-size: 14px;
    color: var(--textSecondary);
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid var(--border);
    margin-bottom: 8px;
}

.uploadIcone {
    color: var(--text) !important;
    font-size: 24px !important;
    transition: color 0.3s;
}