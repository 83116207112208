.container {
    border-top: 1px solid var(--border);
}

.acoes {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding: 24px 24px 0px 24px;
    border-top: 1px solid var(--border);
}

.campos {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.lista {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.titulo {
    font-size: 14px;
    color: var(--text);
    font-weight: 500;
}

.listaItens {
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid var(--border);
    max-height: 180px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    max-width: 470px;
}

.iconeContainer {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--background);
    border-radius: 50%;
    cursor: pointer;
}

.icone {
    user-select: none;
    padding-right: 1px;
    padding-bottom: 1px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}