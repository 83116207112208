.container {
  flex: 1;
  border-bottom: 1px solid var(--border);
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.aba {
  font-size: 14px;
  font-weight: 600;
  color: var(--text);
  padding-bottom: 8px;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
}

.abaSelecionada {
  border-bottom: 2px solid var(--primary);
}