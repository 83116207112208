.container {
    flex: 1;
    background-color: var(--light-gray);
    display: flex;
    flex-direction: column;
}

.table {
    width: 100%;
    border-top: 1px solid var(--border);
    border-left: 1px solid var(--border);
    border-right: 1px solid var(--border);
    border-radius: 12px 12px 0px 0px;
    border-spacing: 0;
}

.icon {
    font-size: 16px !important;
}

.imagem {
    width: 18px;
    height: 18px;
    border-radius: 50%;
}

.table tbody {
    background-color: var(--light-gray);
}

.table th {
    justify-content: space-between;
    align-items: center;
    padding: 0px 24px;
    background-color: var(--light-gray);
    border-bottom: 1px solid var(--border);
    font-size: 14px;
    color: var(--textSecondary);
    font-weight: 700;
}

.cabecalho {
    padding: 12px 0;
    display: flex;
    gap: 4px;
    user-select: none;
    cursor: pointer;
}

.table td {
    padding: 14px 24px;
}

.table tr {
    border-bottom: 1px solid var(--border);
}

.table tr:hover {
    background-color: var(--light-gray);
}

.table th {
    padding-bottom: 0px;
}

.table tbody tr:not(:last-child) td {
    border-bottom: 1px solid var(--border);
}

.valor {
    color: var(--text);
    font-size: 14px;
}

.footer {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;
    gap: 24px;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid var(--border);
    min-height: 42px;
}

.paginas {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.pagina {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: var(--textSecondary);
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.paginaAtiva {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: var(--text);
    background-color: var(--light-gray);
    width: 30px;
    height: 30px;
    border-radius: 8px;
}

.botoes {
    display: flex;
    align-items: center;
    min-width: 120px;
}

.editar {
    font-size: 14px;
    padding: 12px 24px 12px 12px !important;
    text-align: center;
    color: var(--primary);
    font-weight: 600;
    cursor: pointer;
}

.checkbox {
    width: 16px;
}