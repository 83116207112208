.graficosWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.titulo {
    font-size: 16px;
    font-weight: 700;
    color: var(--text);
    padding-bottom: 8px;
}