.container {
    padding: 24px 24px 12px 24px;
}

.wrapper {
    display: flex;
    height: 100%;
}

.painelEsquerdo {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
}

.titulo {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 8px;
}

.subtitulo {
    font-size: 14px;
    color: var(--textSecondary);
}

.voltar {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--textSecondary);
    font-size: 14px;
    cursor: pointer;
    display: none;
}