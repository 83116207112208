.container {
    width: '100%';
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-height: 300px;
    max-height: 300px;
    padding-right: 8px;

}

.item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 12px 16px;
    border-radius: 8px;
    gap: 16px;
    border-bottom: 1px solid var(--border);
    cursor: pointer;
}

.infos {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.titulo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
}

.valor {
    font-size: 12px;
    font-weight: 500;
}

.iconeContainer {
    width: 36px;
    height: 36px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.descricao {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--text);
}

.barraContainer {
    border-radius: 2px;
    flex: 1;
    background-color: var(--border);
    border-radius: 6px;
}

.barraWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.barra {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    color: var(--white);
    background-color: var(--primary);
    transition: width 1.5s ease;
}

.percentual {
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 2px;
}

@media (max-width: 1366px) {
    .titulo {
        font-size: 12px;
    }

    .valor {
        font-size: 12px;
    }

    .descricao {
        font-size: 12px;
    }

    .barra {
        font-size: 10px;
    }
}