.container {
    flex: 1;
    color: var(--text);
    border-radius: 12px;
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 600;
    z-index: 999;
    user-select: none;
}

.wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
}

.background {
    padding: 16px;
    border-radius: 12px;
    border: 1px solid;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.icone {
    font-size: 18px !important;
    cursor: pointer;
}

.barra {
    height: 2px;
    border-radius: 12px;
}

.fechar {
    padding-left: 4px;
    font-size: 14px !important;
    color: var(--text);
    cursor: pointer;
}