.botao {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  border-radius: 8px;
  padding: 10px 14px;
}

.icone {
  font-size: 14px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.texto {
  font-size: 12px;
  font-weight: 600;
}

.imagem {
  width: 16px;
  height: 16px;
  padding-right: 8px;
}

.hint {
  display: none;
  cursor: pointer;
  position: absolute;
  background-color: var(--white);
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 12px;
  bottom: calc(100% + 8px);
  border: 1px solid var(--border);
  right: 0;
  font-weight: 500;
}

.botao:hover .hint {
  display: block;
}