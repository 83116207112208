.container {
    flex: 1;
    min-width: 450px;
    max-height: 70vh;
    overflow: auto;
}

.container::-webkit-scrollbar {
    width: 12px;
}

.wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    padding: 0 24px;
    padding-top: 8px;
}

/* .switchs {
    display: flex;
    align-items: center;
    background: var(--medium-gray);
    padding: 4px;
    border-radius: 8px;
}

.switch {
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 700;
    padding: 8px 24px;
    border-radius: 8px;
    cursor: pointer;
} */

.opcoes {
    display: flex;
    align-items: center;
    gap: 16px;
}

.painel {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--border);
    border-radius: 16px;
    padding: 24px 24px;
    width: 480px;
}

.cabecalho {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding-bottom: 16px;
}

.infos {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}

.titulo {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 700;
    color: var(--text);
}

.subtitulo {
    font-size: 12px;
    color: var(--textSecondary);
}

.valores {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 4px;
}

.valor {
    font-size: 32px;
    font-weight: 700;
}

.label {
    font-size: 14px;
    color: var(--textSecondary);
    padding-bottom: 4px;
}

.detalhes {
    padding-top: 16px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    display: flex;
    flex-direction: column;
}

.detalhesTitulo {
    font-size: 14px;
    font-weight: 700;
    color: var(--text);
}

.detalhesSubtitulo {
    font-size: 12px;
    color: var(--textSecondary);
}

.funcionalidades {
    display: flex;
    padding-top: 24px;
    gap: 24px;
}

.lista {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.icone {
    width: 16px;
    height: 16px;
    color: var(--success);
    font-size: 13px !important;
    background-color: var(--success-light);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item {
    display: flex;
    gap: 8px;
    font-size: 12px;
    color: var(--text);
}

.termos {
    width: 100%;
    text-align: center;
    margin-bottom: 8px;
}

.termosTexto {
    font-size: 14px;
    color: var(--textSecondary);
}