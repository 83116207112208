.container {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 890;
    backdrop-filter: blur(4px);
    overflow: hidden;
    gap: 24px;
}

.texto {
    font-size: 16px;
    color: var(--text);
    text-align: center;
    font-weight: 700;
}

.icone {
    color: var(--white);
    font-size: 42px !important;
}