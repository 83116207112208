.container {
    display: flex;
    flex-direction: column;
}

.wrapper {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid var(--border);
    gap: 8px;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: var(--background);
}

.input {
    flex: 1;
    border: none;
    font-size: 14px;
    color: var(--text);
    outline: none;
    font-weight: 500;
}

.input:disabled {
    background-color: transparent;
}

.icone {
    user-select: none;
    font-size: 18px !important;
    color: var(--placeholder);
}

.labelContainer {
    display: flex;
    gap: 4px;
}

.label {
    font-size: 14px;
    font-weight: 500;
    color: var(--text);
    ;
}

.hint {
    font-size: 12px;
    color: var(--placeholder);
}

.obrigatorio {
    font-size: 12px;
    color: #FF4D4F;
}

@media (max-width: 768px) {
    .wrapper {
        min-width: 100%;
    }
}