.container {
    position: relative;
    display: inline-block;
}

.tooltip {
    visibility: hidden;
    background-color: var(--text);
    color: #FFFFFF;
    text-align: center;
    border-radius: 4px;
    padding: 6px 8px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    right: 0;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
}

.container:hover .tooltip {
    visibility: visible;
    opacity: 1;
}