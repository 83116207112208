.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* Alinha ao topo */
    background-color: var(--opacity);
    z-index: 888;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(4px);
    overflow: hidden;
    overflow-y: auto;
    /* Para permitir rolagem caso o conteúdo seja grande */
    padding-top: 10vh;
    padding-bottom: 10vh;
    /* Ajuste conforme necessário */
}

.wrapper {
    background-color: var(--background);
    border-radius: 12px;
    padding: 0px 0px 24px 0px;
    /* Limita a altura para evitar que saia da tela */
    box-shadow: 0px 0px 3px var(--shadow);
}

.cabecalho {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
    padding: 24px 24px 16px 24px;
}

.iconeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border);
    border-radius: 99px;
    height: 40px;
    width: 40px;
    color: var(--text) !important;
}

.infoContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
    padding-right: 32px;
}

.titulo {
    font-weight: 700;
    color: var(--text);
}

.subtitulo {
    font-size: 13px;
    color: var(--textSecondary);
}

.conteudo {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

@media (max-width: 768px) {
    .container {
        align-items: center;
        padding: 0 16px 0 16px;
    }

    .wrapper {
        max-height: 90vh;
    }
}