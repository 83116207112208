.container {
    padding: 16px 17vw;
    transition: all 0.3s ease;
}

.wrapperHeader {
    border: 1px solid var(--border);
    border-radius: 8px;
    background-color: var(--light-gray);
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 32px;
    justify-content: space-between;
}

.logo {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px
}

.logo img {
    width: 32px;
    height: 32px
}

.logo span {
    font-size: 18px;
    font-weight: 800
}

.menus {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 16px;
}

.menu {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    color: var(--text);
    cursor: pointer;
}

.icone {
    font-size: 18px !important;
}

.acoes {
    display: flex;
    align-items: center;
    gap: 8px;
}

.wrapperMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.tituloPrincipal {
    text-align: center;
    font-size: 46px;
    font-weight: 800;
    color: var(--text);
}

.titulo {
    text-align: center;
    font-size: 36px;
    font-weight: 800;
    color: var(--text);
}

.subtitulo {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: var(--textSecondary);
}

.temaContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding-right: 32px;
    font-size: 14px;
}

.mainImage {
    border-radius: 36px 36px 0px 0px;
    border: 5px solid var(--border);
    border-bottom: 1px solid var(--border);
    width: calc(100% - 42px);
    height: auto;
    margin: 16px 0 0 0;
    padding: 12px 12px;
}

.wrapperFeatures {
    margin: 84px 0;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: flex-end;
}

.wrapperFooter {
    margin: 42px 0;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
    justify-content: flex-start;
}

.dica {
    font-weight: 700;
    color: var(--primary);
}

.features {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
    justify-content: center;
}

.feature {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}

.iconeFeatures {
    color: var(--yellow) !important;
    font-size: 26px !important;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid var(--border);
}

.tituloFeature {
    text-align: center;
    font-weight: 700;
    color: var(--text);
    font-size: 18px;
}

.descricaoFeature {
    text-align: center;
    font-weight: 500;
    color: var(--textSecondary);
}

.declaracao {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.foto {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--background);
    border: 1px solid var(--border);
    margin-bottom: 12px;
}

.autor {
    font-weight: 700;
    color: var(--text);
}

.cargo {
    font-size: 14px;
    font-weight: 500;
    color: var(--textSecondary);
}

.faqs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.faq {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    padding: 24px 0px;
    width: 100%;
}

.faq:not(:last-child) {
    border-bottom: 1px solid var(--border);
}

.pergunta {
    font-weight: 700;
    color: var(--text);
}

.resposta {
    color: var(--textSecondary);
}

.direitos {
    font-size: 14px;
    font-weight: 500;
    color: var(--textSecondary);
}

.divisor {
    width: 100%;
    height: 1px;
    background-color: var(--border);
}

.logoIcone {
    width: 32px;
    height: 32px;
    fill: var(--primary);
}

.opcoes {
    display: flex;
    align-items: center;
    gap: 16px;
}

.painel {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--border);
    border-radius: 16px;
    padding: 24px 24px;
    width: 480px;
}

.cabecalho {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding-bottom: 16px;
}

.infos {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}

.tituloPlano {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 700;
    color: var(--text);
}

.subtituloPlano {
    font-size: 12px;
    color: var(--textSecondary);
}

.valores {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 4px;
}

.valor {
    font-size: 32px;
    font-weight: 700;
}

.label {
    font-size: 14px;
    color: var(--textSecondary);
    padding-bottom: 4px;
}

.detalhes {
    padding-top: 16px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    display: flex;
    flex-direction: column;
}

.detalhesTitulo {
    font-size: 14px;
    font-weight: 700;
    color: var(--text);
}

.detalhesSubtitulo {
    font-size: 12px;
    color: var(--textSecondary);
}

.funcionalidades {
    display: flex;
    padding-top: 24px;
    gap: 24px;
}

.lista {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.iconePlano {
    width: 16px;
    height: 16px;
    color: var(--success);
    font-size: 13px !important;
    background-color: var(--success-light);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item {
    display: flex;
    gap: 8px;
    font-size: 12px;
    color: var(--text);
}

.termos {
    width: 100%;
    text-align: center;
    margin-bottom: 8px;
}

.expandir {
    cursor: pointer;
    font-size: 36px !important;
    color: var(--text);
}

.termosTexto {
    font-size: 14px;
    color: var(--textSecondary);
}

.balas {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.bala {
    cursor: pointer;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--border);
}

.balaAtiva {
    background-color: var(--primary);
}

@media (max-width: 1366px) {
    .menu {
        font-size: 12px
    }
}

/* Responsividade para telas pequenas (smartphones) */
@media (max-width: 768px) {
    .container {
        padding: 8px 5vw;
    }

    .wrapperHeader {
        gap: 16px;
    }

    .wrapperFeatures {
        margin: 62px 0;
    }

    .logo span {
        display: none;
        font-size: 16px !important;
    }

    .menus {
        display: none;
        flex-direction: column;
        gap: 8px;
    }

    .tituloPrincipal {
        font-size: 26px;
    }

    .iconeFeatures {
        font-size: 18px !important;
    }

    .tituloFeature {
        font-size: 16px;
    }

    .descricaoFeature {
        font-size: 14px;
    }

    .subtitulo {
        font-size: 14px;
    }

    .titulo {
        font-size: 22px;
    }

    .dica {
        font-size: 14px;
    }

    .features {
        grid-template-columns: 1fr;
    }

    .mainImage {
        width: 75%;
        margin: 0px 0;
    }

    .autor {
        font-size: 14px;
    }

    .cargo {
        font-size: 12px;
    }

    .pergunta {
        font-size: 16px;
    }

    .resposta {
        font-size: 14px;
    }
}