.container {
    padding: 24px 24px 12px 24px;
}

.wrapper {
    display: flex;
    height: 100%;
}

.painelEsquerdo {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
}

.titulo {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 8px;
}

.subtitulo {
    font-size: 14px;
    color: var(--textSecondary);
}

.voltar {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--textSecondary);
    font-size: 14px;
    cursor: pointer;
    display: none;
}

.formulario {
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.opcoes {
    display: flex;
    align-items: center;
    justify-content: flex-end;

}

.opcao {
    font-size: 12px;
    font-weight: 600;
    color: var(--primary);
    cursor: pointer;
}

.provedores {
    text-align: center;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
}

.hint {
    padding-top: 24px;
    text-align: center;
    font-size: 12px;
}

@media (max-width: 768px) {
    .formulario {
        width: 100%;
    }
}