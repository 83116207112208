.container {
    display: flex;
    border-right: 1px solid var(--border);
    background-color: var(--light-gray);
    width: 260px;
}

.wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px
}

.logo {
    display: flex;
    align-items: center;
    gap: 4px
}

.imagem {
    width: 32px;
    height: 32px;
    color: var(--primary);
    fill: var(--primary);
}

.titulo {
    font-size: 18px;
    font-weight: 800
}

.tituloPlus {
    font-size: 18px;
    font-weight: 800;
    color: var(--yellow);
    padding-left: 4px;
}

.grupoTexto {
    color: var(--textSecondary);
    font-weight: 700;
    font-size: 12px;
}

.grupos {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 12px;
}

.menus {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    cursor: pointer;
    gap: 8px;
    border-radius: 6px;
}

.menuSelecionado {
    background-color: var(--background);
}

.menuIcone {
    color: var(--textSecondary);
}

.menuTexto {
    flex: 1;
    font-weight: 600;
    padding-right: 8px;
    font-size: 14px;
}

.menuAcao {
    color: var(--textSecondary);
}

.profile {
    position: relative;
    background-color: var(--background);
    border: 1px solid var(--border);
    border-radius: 12px;
    padding: 12px;
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
}

.profileImagem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--light-gray);
    font-size: 20px !important;
    color: var(--textSecondary)
}

.profileInfo {
    position: relative;
    max-width: 150px;
    display: flex;
    flex-direction: column;
}

.profileNome {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--text);
    font-weight: 600;
    font-size: 14px;
    word-wrap: break-word;
}

.profileEmail {
    font-size: 12px;
    color: var(--textSecondary);
    word-wrap: break-word;
}

.premium {
    font-size: 14px !important;
    color: var(--yellow);
}

@media (max-width: 768px) {
    .wrapper {
        width: 100%;
    }
}