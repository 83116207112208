.container {
    flex: 1;
    height: 100%;
}

.avisos {
    position: fixed;
    top: 18px;
    right: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    z-index: 999;
}