.container {
    flex: 1;
    width: 520px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 24px;
}

.painel {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.formularios {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 0 0 0;
}

.formulario {
    display: flex;
    flex-direction: column;
    gap: 16 px;
    padding: 8px 0px 24px 0px;
    border-radius: 4px;
    gap: 16px;
    border-bottom: 1px solid var(--border);
}

.formularioSenha {
    display: flex;
    flex-direction: column;
    padding: 8px 0px 24px 0px;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    border-bottom: 1px solid var(--border);
}


.botoes {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding-top: 16px;
}

.campoPersonalizado {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.label {
    font-size: 14px;
    font-weight: 500;
    color: var(--text);
}

.cores {
    display: flex;
    align-items: center;
    gap: 8px;
}

.cor {
    cursor: pointer;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid var(--border);
}

.assinaturaTitulo {
    font-size: 14px;
    font-weight: 700;
    color: var(--text);
}

.assinaturaSubtitulo {
    font-size: 14px;
    color: var(--textSecondary);
}

.beneficios {
    display: flex;
    flex-direction: column;
}

.beneficio {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 0;
}

.beneficioIcone {
    color: var(--success-light);
    font-size: 14px !important;
    background-color: var(--success);
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.beneficioTexto {
    font-size: 14px;
    font-weight: 500;
    color: var(--text);
}

.recorrencia {
    font-size: 14px;
    margin-bottom: 4px;
}