.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(4px);
    z-index: 889;
    gap: 12px;
}

/* .image {
    width: 100px;
    height: 100px;
    animation: spin 1s linear infinite;
} */

.icone {
    color: var(--white);
    font-size: 42px !important;
}