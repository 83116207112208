.titulo {
    font-size: 16px;
    font-weight: 700;
    color: var(--text);
    padding-bottom: 8px;
}

.graficosWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.transacoesWrapper {
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 8px;
    padding-right: 24px;
}

.transacao {
    border-bottom: 1px solid var(--border);
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
}

.transacaoInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.transacaoDescricao {
    font-size: 14px;
    font-weight: 500;
    color: var(--text);
}

.transacaoData {
    font-size: 12px;
    color: var(--textSecondary);
}

.transacaoValor {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
}

.transacaoCategoria {
    width: 36px;
    height: 36px;
    font-size: 12px;
    color: var(--textSecondary);
    background-color: var(--background);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.transacaoTipo {}

.semTransacoes {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--textoSecondary);
}

@media (max-width: 1366px) {
    .transacaoDescricao {
        font-size: 12px;
    }

    .transacaoValor {
        font-size: 12px;
    }
}