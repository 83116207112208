.container {
    flex: 1;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.botoes {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    padding: 8px 24px 0px 24px;
}


.filtros {
    flex: 1;
    display: flex;
    gap: 8px;
    align-items: center;
}

.dataContainer {
    overflow: auto;
}

@media (max-width: 1024px) {}