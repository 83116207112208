.container {
    height: 100%;
}

.wrapper {
    display: flex;
    height: 100%;
}

.conteudo {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
}

.conteudo::-webkit-scrollbar-thumb {
    background: var(--thumb) !important;
}

.conteudo::-webkit-scrollbar-thumb:hover {
    background: var(--thumb) !important;
}



@media (max-width: 768px) {
    .wrapper {
        flex-direction: column;
    }
}