.graficosWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.infosSaldo {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: flex-end;
}

.saldoConta {
    font-size: 24px;
    font-weight: 700;
}

.labelSaldo {
    font-size: 14px;
    color: var(--textSecondary);
}

.graficos {
    display: flex;
    gap: 8px;
    align-items: center;
}

.titulo {
    font-size: 16px;
    font-weight: 700;
    color: var(--text);
    padding-bottom: 8px;
}

@media (max-width: 1366px) {
    .saldoConta {
        font-size: 20px;
    }
}